import { Link } from "@remix-run/react";
import { iconMap } from "~/components/ui/icons";
import { NavigationItem } from "../navigation/types";

function FooterNavItem({ navItem }: { navItem: NavigationItem }) {
  const Icon = navItem.icon ? iconMap.get(navItem.icon) : undefined;
  return (
    <Link
      to={navItem.url || ""}
      className="text-muted hover:text-muted/80 text-sm font-medium underline"
    >
      <div className="flex items-center ">
        {Icon === undefined ? null : <Icon className="size-4 mr-1" />}
        {navItem.label}
      </div>
    </Link>
  );
}

function FooterNavigation({ navItems }: { navItems: NavigationItem[] }) {
  return (
    <nav className="flex items-center gap-x-2 flex-wrap">
      {navItems.map((entry, index) => (
        <FooterNavItem key={index} navItem={entry} />
      ))}
    </nav>
  );
}

export function Footer({ navItems }: { navItems: NavigationItem[] }) {
  return (
    <footer className="border-t-2 border-t-border bg-primary-foreground py-1 text-nowrap ">
      <div className="mx-auto px-4 max-w-4xl">
        <div className="flex items-center flex-wrap gap-x-4 justify-between md:justify-end max-w-full">
          <FooterNavigation navItems={navItems} />
          <div className="text-sm italic text-muted">
            © 2024 Fizika sa Sonjom
          </div>
        </div>
      </div>
    </footer>
  );
}
